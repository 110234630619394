<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        Atome, the chemistry cat, ran a TLC. Instead of migrating upwards on the plate, the compound
        simply dissolved in the solvent used for elution. What mistake did Atome and his clumsy paws
        make?
      </p>

      <v-radio-group v-model="inputs.question1Value" class="ml-6 mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q9',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
      },
      options: [
        {text: 'He did not put enough of the compound on the plate', value: 'notEnoughCompound'},
        {text: 'He used a plate that was highly scratched at the bottom', value: 'scratchedPlate'},
        {text: 'The solvent he chose for elution was too polar', value: 'eluentTooPolar'},
        {
          text: 'The baseline he drew was lower than the level of the solvent',
          value: 'baselineBelowSolvent',
        },
      ],
    };
  },
};
</script>
